<template>
  <div class="cNavy fs0" style="min-height: 100vh;">
    <div class="pf oh  applicantSideBar fs0 fs0 cov" style="width: 300px;" :style="{ backgroundImage: 'url(' + require('@/assets/imgs/patternBg/appSideBg.png') + ')' }"> 
      
      <div class="z100 ph10" style="max-width: 100%;" >
        <img src="@/assets/imgs/logo_new.png" alt=""  class="w100" style="max-width:200px;" >
      </div>
      <div class="oya h100 c mt50 sideBarInner">
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Dashboard' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' : $route.name == 'Dashboard' }"><span class="fs24 mdi mdi-apps vm"></span><span class="ml4 lh24 vm cap">{{ $t('dashboard') }}</span></div>
          </router-link>
        </div>
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Profile' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' : $route.name == 'Profile' }"><span class="fs24 mdi mdi-account vm"></span><span class="ml4 lh24 vm cap">{{ $t('profile') }}</span></div>
          </router-link>
        </div>
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Members' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' : $route.name == 'Members' }"><span class="fs24 mdi mdi-account-multiple vm"></span><span class="ml4 lh24 vm cap">{{ $t('members') }}</span></div>
          </router-link>
        </div>
        <!-- <div class="pv8" v-if="this.$store.state.team.qualified!='not-qualified'">
          <router-link class="g_nm" :to="{ name: 'BookMentor' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' : $route.name == 'BookMentor' }"><span class="fs24 mdi mdi-message mt4"></span><span class="ml4 vm cap">{{ $t('mentors') }}</span></div>
          </router-link>
        </div> -->
        
        <!-- <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Feedback' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' : $route.name == 'Feedback' }"><span class="fs24 mdi mdi-chart-bar vm"></span><span class="ml4 lh24 vm cap">{{ $t('feedback') }}</span><span class="danger ml4">(0)</span></div>
          </router-link>
        </div> -->
        <div class="pv8" v-if="this.user.role=='admin'">
          <router-link class="g_nm" :to="{ name: 'InviteMember' }">
            <div class="l applicatMenuTab g_nm"  :class="{'active' : $route.name == 'InviteMember' }"><span class="fs24 mdi mdi-chart-bar vm"></span><span class="ml4 lh24 vm cap">{{ $t('Invite member') }}</span></div>
          </router-link>
        </div>
        <div class="pv8" v-if="this.user.role=='admin' && $store.state.team?.applicants?.length<4 && $store.state.team.qualified!='not-qualified'">
          <router-link class="g_nm" :to="{ name: 'MergeTeam' }">
            <div class="l applicatMenuTab g_nm"  :class="{'active' : $route.name == 'MergeTeam' }"><span class="fs24 mdi mdi-account-multiple-plus-outline vm"></span><span class="ml4 lh24 vm">{{ $t('Merge with a team') }}</span></div>
          </router-link>
        </div>
        <div class="mt30 c">
          <div class="logoutApplicatn g_nm pointer l" @click="logoutApplicant()"><span class="fs24 mdi mdi-logout mt" ></span><span class=" ml4 cap fs14 vm ">{{ $t('logout') }}</span></div>
        </div>  
      </div>
      
      <div class="ov mt10 ml15 ra h m_sb"><span class=" pointer fs28 ba danger mdi mdi-close-thick z200" @click="closeMenu()"></span></div>
    </div>
    <div class="aplicatanForm pt40 l ph30">
      <router-view></router-view>
    </div>  
    <div v-if="$store.state.loader==1" class="pf ov  Loader300" style="z-index: 999999;">
      <div class="bgw h100"><div class="cc">
        <img src="@/assets/imgs/loader.gif"  style="max-width: 250px; opacity: 0.7;"/>
        <!-- <img src="@/assets/imgs/logo_new.png" alt="" class="loaderImage"> -->
      </div></div>
    </div>
    <div class="ov20 z900 la ba pointer " @click="changeLang()"><span class="mdi mdi-earth fs30 cNavy vm mt2"></span></div>
    <div class="ov20 z200 ra ba h m_sb"><span class="pointer mdi mdi-menu fs30 cNavy vm" @click="openMenu()"></span> </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicantLayout',
  data(){
    return{
      disabledSubmit:false,
      errors:0,
      at:null,
      user:{},
    }
  },
  created(){
    if(this.$cookies.isKey("_HackLang")){
      if(this.$cookies.get("_HackLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
    if(this.$cookies.isKey("_HackAppU") && this.$cookies.isKey("_HackAppAT")){
      this.at=this.$cookies.get("_HackAppAT")
      this.user=this.$cookies.get("_HackAppU")
    }
    else{
      this.$router.push({ name: 'ApplicantLogin'});
    }
    
  },
  
  methods: {
    send_invitation(){
      if(this.disabledSubmit==false){
        this.disabledSubmit=true;
        this.$store.state.loader=1;
        this.errors=0;
        if(this.memberEmail==null || this.memberEmail==0 || this.memberEmail== undefined | this.memberEmail== ""){
          $('.VEmail').html(this.$t('Email_is_required'));
          $('.VEmail').slideDown();
          $('.VEmailInput').addClass('error');
          this.errors = 1;
        }
        else{
          if(this.$store.state.emailRegex.test(this.memberEmail) ==false){
            $('.VEmail').html(this.$t('Enter_a_vailed_email'));
            $('.VEmail').slideDown();
            $('.VEmailInput').addClass('error');
            this.errors = 1;
         }
          else{
            $('.VEmail').slideUp();
            $('.VEmailInput').removeClass('error');
          }
        }
        if(this.errors==0){
          this.axios.post(this.$store.state.BaseApiURL + "applicant/send-invitation", {email:this.memberEmail},{
        headers: {'Authorization': `Bearer ${this.at}`}}
          ).then(response => {
            if(response.data.success==1){
              this.showInviteMember=false;
              this.$toast.open({ message: this.$t('invitation_send_successfully'), type: 'success', duration:5000, position:'top-right',});
              this.$store.state.loader=0;
              this.disabledSubmit=false;

            }
            else{
              if(response.data.error=="Already registered"){
                this.$store.state.loader=0;
                this.disabledSubmit=false;
                this.$toast.open({ message: this.$t('This user already has team'), type: 'error', duration:5000, position:'top-right',});
              }
              else{

              }
              
            }
          })
          .catch(error => {
            this.$toast.open({ message: this.$t('failed_to_get_data'), type: 'error', duration:5000, position:'top-right',});
            this.$store.state.loader=0;
            this.disabledSubmit=false;
          });
        }
        else{
          this.$toast.open({ message: this.$t('Please_fill_all_fileds'), type: 'error', duration:5000, position:'top-right'});
          this.$store.state.loader=0;
          this.disabledSubmit=false;
        }
      }
    
    },
    logoutApplicant(){
      this.$cookies.remove("_HackAppAT")
      this.$cookies.remove("_HackAppU")
      this.$router.push({ name: 'ApplicantLogin'});  
    },
    changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
    openMenu(){
      $('.applicantSideBar').addClass('active');
    },
    closeMenu(){
      $('.applicantSideBar').removeClass('active');
    }
  }
}
</script>
